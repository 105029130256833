import { unebSubjectActions } from "../../actions";
import initialState from "../../initialState";

function unebSubject(state = initialState.unebSubject, actions) {
  switch (actions.type) {
    case unebSubjectActions.GET_UNEB_SUBJECT_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case unebSubjectActions.GET_UNEB_SUBJECT_SUCCESS:
      return {
        ...state,
        unebSubjects: actions.data,
        loading: false,
      };
    case unebSubjectActions.GET_UNEB_SUBJECT_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case unebSubjectActions.GET_UNEB_CENTERS_REQUEST:
      return {
        ...state,
        getUnebCenterError: {},
        gettingUnebCenters: true,
      };
    case unebSubjectActions.GET_UNEB_CENTERS_SUCCESS:
      return {
        ...state,
        unebCenters: actions.data,
        gettingUnebCenters: false,
      };
    case unebSubjectActions.GET_UNEB_CENTERS_ERROR:
      return {
        ...state,
        getUnebCenterError: actions.error,
        gettingUnebCenters: false,
      };
    default:
      return state;
  }
}

export default unebSubject;
