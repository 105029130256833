import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal, Space } from "antd";
import { isFunction, isObject } from "lodash";
import PropTypes, { any, object } from "prop-types";
import React, { isValidElement, useEffect, useState } from "react";
import SubmitButton from "./SubmitButton";

function FormModal({
  formTitle,
  children,
  defaultShow,
  onCloseModal,
  handleSubmit,
  submitButtonProps,
  footerExtras,
  ...props
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onCloseModal();
  };

  useEffect(() => {
    setShow(defaultShow);
  }, [defaultShow]);

  const footerComponent = [
    <SubmitButton
      text="CANCEL"
      key="cancel"
      danger
      onClick={onCloseModal}
      iconBefore={<CloseCircleOutlined />}
    />,
  ];

  if (isFunction(handleSubmit))
    footerComponent.push(
      <SubmitButton
        key="submit"
        onClick={handleSubmit}
        {...submitButtonProps}
      />,
    );

  if (isObject(footerExtras) && isValidElement(footerExtras))
    footerComponent.splice(1, 0, footerExtras);

  return (
    <Modal
      closable
      open={show}
      onCancel={handleClose}
      keyboard={false}
      maskClosable={false}
      centered
      cancelText="Cancel"
      okText="Submit"
      footer={<Space>{footerComponent}</Space>}
      title={
        <div className="text-primary text-uppercase text-sm fw-bold">
          {formTitle}
        </div>
      }
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      {...props}
    >
      {children}
    </Modal>
  );
}

FormModal.defaultProps = {
  children: null,
  defaultShow: false,
  handleSubmit: null,
  submitButtonProps: {},
  footerExtras: null,
};

FormModal.propTypes = {
  formTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([any]),
  defaultShow: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitButtonProps: PropTypes.oneOfType([object]),
  footerExtras: PropTypes.element,
};

export default FormModal;
