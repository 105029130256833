import { all } from "redux-saga/effects";
import metadata from "./app/metadata";
import server from "./app/server";
import auth from "./auth";
import runningAdmission from "./admission/runningAdmission";
import myApplicationForm from "./admission/myApplicationForm";
import applicationSection from "./admission/applicationSection";
import unebSubject from "./app/unebSubject";
import shared from "./shared";

const rootSaga = function* root() {
  yield all([
    ...auth,
    ...metadata,
    ...server,
    ...runningAdmission,
    ...applicationSection,
    ...unebSubject,
    ...myApplicationForm,
    ...shared,
  ]);
};

export default rootSaga;
