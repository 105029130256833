import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { unebSubjectActions } from "../../actions";

function* getAllUnebSubjects(actions) {
  try {
    const response = yield axios({
      url: "/pujab/applicant-portal/uneb-results/uneb-subjects",
      method: "GET",
      params: actions.data,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECT_SUCCESS,
      data: response.unebSubjects,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* getAllUnebCenters(actions) {
  try {
    const response = yield axios({
      url: "/pujab/applicant-portal/applications/uneb-centers",
      method: "GET",
      params: actions.data,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_CENTERS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.GET_UNEB_CENTERS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUnebSubjects() {
  yield takeLatest(
    unebSubjectActions.GET_UNEB_SUBJECT_REQUEST,
    getAllUnebSubjects,
  );
}

function* watchGetUnebCenters() {
  yield takeLatest(
    unebSubjectActions.GET_UNEB_CENTERS_REQUEST,
    getAllUnebCenters,
  );
}

const forkFunctions = [fork(watchGetUnebSubjects), fork(watchGetUnebCenters)];

export default forkFunctions;
