import axios from "axios";
import FileSaver from "file-saver";
import { fork, put, takeLatest } from "redux-saga/effects";
import { sharedActions } from "../actions";

function* downloadPDFFile(actions) {
  try {
    const { url, fileName, data, params, ...rest } = actions;

    yield axios({
      url,
      method: "POST",
      data,
      params,
      ...rest,
      responseType: "blob",
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: "application/pdf",
        }),
        `${fileName}.pdf`,
      );
    });
    yield put({
      type: sharedActions.DOWNLOAD_PDF_FILE_SUCCESS,
      data: { server: { message: "Report downloaded!", status: true } },
    });
  } catch (error) {
    yield put({
      type: sharedActions.DOWNLOAD_PDF_FILE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadExcelFile(actions) {
  try {
    const { url, data, fileName, ...rest } = actions;

    yield axios({
      url,
      method: "POST",
      data,
      ...rest,
      responseType: "blob",
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `${fileName}.xlsx`,
      );
    });
    yield put({
      type: sharedActions.DOWNLOAD_EXCEL_FILE_SUCCESS,
      data: { server: { message: "Report downloaded!", status: true } },
    });
  } catch (error) {
    yield put({
      type: sharedActions.DOWNLOAD_EXCEL_FILE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadExcelFile(actions) {
  try {
    const { url, data, ...rest } = actions;

    const response = yield axios({
      url,
      method: "POST",
      data,
      ...rest,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 300000,
    });

    yield put({
      type: sharedActions.UPLOAD_EXCEL_FILE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: sharedActions.UPLOAD_EXCEL_FILE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchDownloadExcelFile() {
  yield takeLatest(
    sharedActions.DOWNLOAD_EXCEL_FILE_REQUEST,
    downloadExcelFile,
  );
}

function* watchDownloadPDFFile() {
  yield takeLatest(sharedActions.DOWNLOAD_PDF_FILE_REQUEST, downloadPDFFile);
}

function* watchUploadExcelFile() {
  yield takeLatest(sharedActions.UPLOAD_EXCEL_FILE_REQUEST, uploadExcelFile);
}

export default [
  fork(watchDownloadExcelFile),
  fork(watchUploadExcelFile),
  fork(watchDownloadPDFFile),
];
