const applicationSection = {
  GET_APPLICATION_SECTION_REQUEST: "GET_APPLICATION_SECTION_REQUEST",
  GET_APPLICATION_SECTION_SUCCESS: "GET_APPLICATION_SECTION_SUCCESS",
  GET_APPLICATION_SECTION_ERROR: "GET_APPLICATION_SECTION_ERROR",

  CREATE_APPLICATION_SECTION_SUCCESS: "CREATE_APPLICATION_SECTION_SUCCESS",
  CREATE_APPLICATION_SECTION_REQUEST: "CREATE_APPLICATION_SECTION_REQUEST",
  CREATE_APPLICATION_SECTION_ERROR: "CREATE_APPLICATION_SECTION_ERROR",

  UPDATE_APPLICATION_SECTION_SUCCESS: "UPDATE_APPLICATION_SECTION_SUCCESS",
  UPDATE_APPLICATION_SECTION_REQUEST: "UPDATE_APPLICATION_SECTION_REQUEST",
  UPDATE_APPLICATION_SECTION_ERROR: "UPDATE_APPLICATION_SECTION_ERROR",

  TRANSFER_PRN_PAYMENT_SUCCESS: "TRANSFER_PRN_PAYMENT_SUCCESS",
  TRANSFER_PRN_PAYMENT_REQUEST: "TRANSFER_PRN_PAYMENT_REQUEST",
  TRANSFER_PRN_PAYMENT_ERROR: "TRANSFER_PRN_PAYMENT_ERROR",

  DELETE_APPLICATION_SECTION_SUCCESS: "DELETE_APPLICATION_SECTION_SUCCESS",
  DELETE_APPLICATION_SECTION_REQUEST: "DELETE_APPLICATION_SECTION_REQUEST",
  DELETE_APPLICATION_SECTION_ERROR: "DELETE_APPLICATION_SECTION_ERROR",

  GET_UNEB_RESULT_REQUEST: "GET_UNEB_RESULT_REQUEST",
  GET_UNEB_RESULT_SUCCESS: "GET_UNEB_RESULT_SUCCESS",
  GET_UNEB_RESULT_ERROR: "GET_UNEB_RESULT_ERROR",

  CLEAR_UNEB_RESULT: "CLEAR_UNEB_RESULT",

  SET_CURRENT_APPLICATION_FORM: "SET_CURRENT_APPLICATION_FORM",
  SET_SCHOOL_APPLICATION_FORMS: "SET_SCHOOL_APPLICATION_FORMS",

  getApplicationSection: (sectionUrl) => ({
    type: applicationSection.GET_APPLICATION_SECTION_REQUEST,
    sectionUrl,
  }),

  createApplicationSection: (sectionUrl, payload, config = null) => ({
    type: applicationSection.CREATE_APPLICATION_SECTION_REQUEST,
    sectionUrl,
    payload,
    config,
  }),

  updateApplicationSection: (sectionUrl, payload, config = null) => ({
    type: applicationSection.UPDATE_APPLICATION_SECTION_REQUEST,
    sectionUrl,
    payload,
    config,
  }),

  deleteApplicationSection: (sectionUrl, sectionId, data) => ({
    type: applicationSection.DELETE_APPLICATION_SECTION_REQUEST,
    sectionUrl,
    sectionId,
    data,
  }),

  getApplicantUnebResult: (data) => ({
    type: applicationSection.GET_UNEB_RESULT_REQUEST,
    data,
  }),

  transferPRNPayment: (applicationId, data) => ({
    type: applicationSection.TRANSFER_PRN_PAYMENT_REQUEST,
    data,
    applicationId,
  }),

  clearUnebResults: () => ({
    type: applicationSection.CLEAR_UNEB_RESULT,
  }),

  setCurrentApplicationForm: (payload = {}) => ({
    type: applicationSection.SET_CURRENT_APPLICATION_FORM,
    payload,
  }),

  setSchoolApplicationForms: (payload = []) => ({
    type: applicationSection.SET_SCHOOL_APPLICATION_FORMS,
    payload,
  }),
};

export default applicationSection;
