import React from "react";
import PropTypes, { object } from "prop-types";
import { Button } from "antd";
import { toUpper } from "lodash";

function SubmitButton({
  loading,
  text,
  loadingText,
  iconBefore,
  iconAfter,
  className,
  ...props
}) {
  return (
    <Button
      htmlType="submit"
      type="primary"
      loading={loading}
      icon={iconBefore}
      {...props}
      className="text-uppercase fw-bold"
    >
      {toUpper(loading ? loadingText : text)}
      {iconAfter}
    </Button>
  );
}

SubmitButton.defaultProps = {
  loading: false,
  text: "Save",
  loadingText: "Saving...",
  iconBefore: null,
  iconAfter: null,
  className: null,
};

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  className: PropTypes.string,
  iconBefore: PropTypes.oneOfType([object]),
  iconAfter: PropTypes.oneOfType([object]),
};

export default SubmitButton;
