const setting = {
  setting: {
    selectedMenu: "running-admission",
    sideMenuTab: {},
    admissionFormSections: [],
    runningAdmissionTab: "all-running-admissions",
    applicationTab: "forms",
    showSelectProgrammeModal: false,
    showPRNModal: false,
  },
};

export default setting;
