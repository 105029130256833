import React from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider, theme } from "antd";
import App from "./App";
import rootSaga from "./config/saga";
import history from "./config/services/historyService";
import httpService from "./config/services/httpService";
import configureStore from "./config/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const store = configureStore();
store.runSaga(rootSaga);
httpService.setupInterceptors(store, history);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1F8A70",
              colorWarning: "#F29727",
              algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
              // paddingContentVertical: 6,
              // borderRadius: 6,
              // colorBgContainer: "#EEEDED",
              fontSize: 13,
              // colorBorder: "#9BA4B5",
            },
          }}
          componentSize="middle"
        >
          <App />
          <ToastContainer />
        </ConfigProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
