const myApplicationForm = {
  GET_MY_APPLICATION_HISTORY_REQUEST: "GET_MY_APPLICATION_HISTORY_REQUEST",
  GET_MY_APPLICATION_HISTORY_SUCCESS: "GET_MY_APPLICATION_HISTORY_SUCCESS",
  GET_MY_APPLICATION_HISTORY_ERROR: "GET_MY_APPLICATION_HISTORY_ERROR",

  GET_MY_ADMISSIONS_REQUEST: "GET_MY_ADMISSIONS_REQUEST",
  GET_MY_ADMISSIONS_SUCCESS: "GET_MY_ADMISSIONS_SUCCESS",
  GET_MY_ADMISSIONS_ERROR: "GET_MY_ADMISSIONS_ERROR",

  GENERATE_PAYMENT_REFERENCE_REQUEST: "GENERATE_PAYMENT_REFERENCE_REQUEST",
  GENERATE_PAYMENT_REFERENCE_SUCCESS: "GENERATE_PAYMENT_REFERENCE_SUCCESS",
  GENERATE_PAYMENT_REFERENCE_ERROR: "GENERATE_PAYMENT_REFERENCE_ERROR",

  GET_PAYMENT_REFERENCE_REQUEST: "GET_PAYMENT_REFERENCE_REQUEST",
  GET_PAYMENT_REFERENCE_SUCCESS: "GET_PAYMENT_REFERENCE_SUCCESS",
  GET_PAYMENT_REFERENCE_ERROR: "GET_PAYMENT_REFERENCE_ERROR",

  DOWNLOAD_APPLICATION_FORM_REQUEST: "DOWNLOAD_APPLICATION_FORM_REQUEST",
  DOWNLOAD_APPLICATION_FORM_SUCCESS: "DOWNLOAD_APPLICATION_FORM_SUCCESS",
  DOWNLOAD_APPLICATION_FORM_ERROR: "DOWNLOAD_APPLICATION_FORM_ERROR",

  DOWNLOAD_ADMISSION_LETTER_REQUEST: "DOWNLOAD_ADMISSION_LETTER_REQUEST",
  DOWNLOAD_ADMISSION_LETTER_SUCCESS: "DOWNLOAD_ADMISSION_LETTER_SUCCESS",
  DOWNLOAD_ADMISSION_LETTER_ERROR: "DOWNLOAD_ADMISSION_LETTER_ERROR",

  SHOW_PREVIEW_MODAL: "SHOW_PREVIEW_MODAL",
  SET_SELECTED_APPLICATION_FORM: "SET_SELECTED_APPLICATION_FORM",
  SHOW_VISA_MODAL: "SHOW_VISA_MODAL",

  getMyAdmissionForms: (data) => ({
    type: myApplicationForm.GET_MY_ADMISSION_FORMS_REQUEST,
    data,
  }),

  getMyApplicationHistory: () => ({
    type: myApplicationForm.GET_MY_APPLICATION_HISTORY_REQUEST,
  }),

  getMyAdmissions: () => ({
    type: myApplicationForm.GET_MY_ADMISSIONS_REQUEST,
  }),

  generatePaymentReference: (data) => ({
    type: myApplicationForm.GENERATE_PAYMENT_REFERENCE_REQUEST,
    data,
  }),

  getPaymentReference: (runningAdmissionId, data) => ({
    type: myApplicationForm.GET_PAYMENT_REFERENCE_REQUEST,
    data,
    runningAdmissionId,
  }),

  downloadApplicationForm: (applicationFormId) => ({
    type: myApplicationForm.DOWNLOAD_APPLICATION_FORM_REQUEST,
    applicationFormId,
  }),

  downloadAdmissionLetter: (
    applicationFormId,
    fromAdmissionStatus = false,
  ) => ({
    type: myApplicationForm.DOWNLOAD_ADMISSION_LETTER_REQUEST,
    applicationFormId,
    fromAdmissionStatus,
  }),

  showPreviewModal: (e = false) => ({
    type: myApplicationForm.SHOW_PREVIEW_MODAL,
    e,
  }),

  showVisaModal: (e = false) => ({
    type: myApplicationForm.SHOW_VISA_MODAL,
    e,
  }),

  setSelectedApplicationForm: (e = {}) => ({
    type: myApplicationForm.SET_SELECTED_APPLICATION_FORM,
    e,
  }),
};

export default myApplicationForm;
