import { SyncOutlined } from "@ant-design/icons";
import React from "react";
import SubmitButton from "./SubmitButton";

function ReloadButton({ ...props }) {
  return (
    <SubmitButton
      iconBefore={<SyncOutlined />}
      text="REFRESH"
      type="dashed"
      danger
      htmlType="button"
      loadingText="Loading..."
      size="small"
      {...props}
    />
  );
}

export default ReloadButton;
