const admission = {
  runningAdmission: {
    gettingActiveAdmission: false,
    getActiveAdmissionError: {},
    runningAdmission: {},

    submittingForm: false,
    submitError: {},
    submittedForm: {},
    myApplicationForms: [],

    programmes: [],
    gettingProgrammes: false,
    getProgrammesError: {},

    applicationForms: [],
    gettingForms: false,
    getFormError: {},

    gettingSubjectCombinations: true,
    getSubjectCombinationError: {},
    subjectCombinations: [],

    gettingSelectedRunningAdmission: false,
    getRunningAdmissionError: {},
  },
  applicationSection: {
    loading: false,
    loadError: {},
    applicationSections: [],

    newFormId: null,
    sectionUrl: null,

    creating: false,
    createError: {},
    createdSection: {},
    currentApplicationForm: {},

    schoolApplicationForms: [],

    updating: false,
    updateError: {},
    updatedSection: {},

    transferringFunds: false,
    transferError: {},
    transferSuccess: {},

    singleApplicationSections: [],
    singleApplicationSection: {},

    deleting: false,
    deleteError: {},
    deleteSuccess: {},

    gettingUnebResults: false,
    unebResultError: {},
    unebResultSuccess: {},
  },
  myApplicationForm: {
    loading: false,
    myApplicationForms: [],
    loadError: {},

    generatingPaymentReference: false,
    paymentReferenceError: {},
    paymentReferenceSuccess: {},

    gettingPaymentReference: false,
    getPaymentReferenceError: {},
    paymentReferences: [],

    gettingApplicationHistory: false,
    applicationHistory: [],
    applicationHistoryError: {},

    downloadingForm: false,
    downloadFormId: null,
    downloadFormError: {},

    selectedApplication: {},
    showPreviewModal: false,

    showVisaModal: false,

    downloadingAdmissionLetter: false,
    downloadAdmissionLetterError: {},

    checkingAdmissions: false,
    myAdmissions: [],
    admissionsError: {},
  },
};

export default admission;
