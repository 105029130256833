import axios from "axios";
import { fork, put, takeLatest } from "redux-saga/effects";
import {
  applicationSectionActions,
  myApplicationFormActions,
  runningAdmissionActions,
} from "../../actions";

function* getApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/pujab/applicant-portal/applications/${actions.sectionUrl}`,
      method: "GET",
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_SUCCESS,
      data: response.data,
      sectionUrl: actions.sectionUrl,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* createApplicationSection(actions) {
  try {
    const { payload } = actions;
    const response = yield axios({
      url: `/pujab/applicant-portal/applications/${actions.sectionUrl}`,
      method: "POST",
      data: payload,
      headers: actions.config,
    });
    yield put({
      type: applicationSectionActions.CREATE_APPLICATION_SECTION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_REQUEST,
      runningAdmissionId: payload?.pujab_running_admission_id,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.CREATE_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* updateApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/pujab/applicant-portal/applications/${actions.sectionUrl}`,
      method: "PUT",
      data: actions.payload,
      headers: actions.config,
    });
    yield put({
      type: applicationSectionActions.UPDATE_APPLICATION_SECTION_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
      sectionUrl: `${actions.sectionUrl.split("/")[0]}/${
        actions.payload.form_id || response.data?.form_id
      }`,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId:
        actions.payload.running_admission_id ||
        response.data?.running_admission_id,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.UPDATE_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* transferPRNPayment(actions) {
  try {
    const { data, applicationId } = actions;

    const response = yield axios({
      url: `/pujab/applicant-portal/applications/transfer-payment/${applicationId}`,
      method: "POST",
      data,
      headers: actions.config,
    });
    yield put({
      type: applicationSectionActions.TRANSFER_PRN_PAYMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: myApplicationFormActions.GET_MY_APPLICATION_HISTORY_REQUEST,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.TRANSFER_PRN_PAYMENT_ERROR,
      error: error.data,
    });
  }
}

function* deleteApplicationSection(actions) {
  try {
    const response = yield axios({
      url: `/pujab/applicant-portal/applications/${actions.sectionUrl}/${actions.sectionId}`,
      method: "DELETE",
    });
    yield put({
      type: applicationSectionActions.DELETE_APPLICATION_SECTION_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
      sectionUrl: `${actions.sectionUrl}/${actions.data.form_id}`,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId: actions.data.running_admission_id,
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.DELETE_APPLICATION_SECTION_ERROR,
      error: error.data,
    });
  }
}

function* getApplicantUnebResult(actions) {
  try {
    const response = yield axios({
      url: `/pujab/applicant-portal/applications/uneb-results`,
      method: "POST",
      data: actions?.data,
    });
    yield put({
      type: applicationSectionActions.GET_UNEB_RESULT_SUCCESS,
      data: { ...response?.data, ...actions?.data },
    });
  } catch (error) {
    yield put({
      type: applicationSectionActions.GET_UNEB_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetApplicationSection() {
  yield takeLatest(
    applicationSectionActions.GET_APPLICATION_SECTION_REQUEST,
    getApplicationSection,
  );
}

function* watchCreateApplicationSection() {
  yield takeLatest(
    applicationSectionActions.CREATE_APPLICATION_SECTION_REQUEST,
    createApplicationSection,
  );
}

function* watchUpdateApplicationSection() {
  yield takeLatest(
    applicationSectionActions.UPDATE_APPLICATION_SECTION_REQUEST,
    updateApplicationSection,
  );
}

function* watchTransferPRNPayment() {
  yield takeLatest(
    applicationSectionActions.TRANSFER_PRN_PAYMENT_REQUEST,
    transferPRNPayment,
  );
}

function* watchDeleteApplicationSection() {
  yield takeLatest(
    applicationSectionActions.DELETE_APPLICATION_SECTION_REQUEST,
    deleteApplicationSection,
  );
}

function* watchGetApplicationResult() {
  yield takeLatest(
    applicationSectionActions.GET_UNEB_RESULT_REQUEST,
    getApplicantUnebResult,
  );
}

const forkFunctions = [
  fork(watchGetApplicationSection),
  fork(watchCreateApplicationSection),
  fork(watchUpdateApplicationSection),
  fork(watchTransferPRNPayment),
  fork(watchDeleteApplicationSection),
  fork(watchGetApplicationResult),
];

export default forkFunctions;
