import axios from "axios";
import { fork, put, takeLatest } from "redux-saga/effects";
import { runningAdmissionActions } from "../../actions";

function* getAllRunningAdmission() {
  try {
    const response = yield axios({
      url: "/pujab/applicant-portal/applications",
      method: "GET",
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* submitApplicationForm(actions) {
  try {
    const { runningAdmissionId, payload } = actions;

    const response = yield axios({
      url: `/pujab/applicant-portal/applications/submit/${runningAdmissionId}`,
      method: "POST",
      data: payload,
    });
    yield put({
      type: runningAdmissionActions.SUBMIT_APPLICANT_FORM_SUCCESS,
      data: response.data,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.SUBMIT_APPLICANT_FORM_ERROR,
      error: error.data,
    });
  }
}

function* getSectionProgrammes(actions) {
  try {
    const { payload } = actions;

    const response = yield axios({
      url: `/pujab/applicant-portal/applications/programmes`,
      method: "GET",
      params: payload,
    });
    yield put({
      type: runningAdmissionActions.GET_SELECTED_SECTION_PROGRAMMES_SUCCESS,
      data: response.data,
      context: payload,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_SELECTED_SECTION_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getSubjectCombinations(actions) {
  try {
    const { payload } = actions;

    const response = yield axios({
      url: `/pujab/applicant-portal/applications/subject-combinations`,
      method: "GET",
      params: payload,
    });
    yield put({
      type: runningAdmissionActions.GET_SUBJECT_COMBINATIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_SUBJECT_COMBINATIONS_SUCCESS,
      error: error.data,
    });
  }
}

function* getRunningAdmissionForms(actions) {
  try {
    const { runningAdmissionId } = actions;

    const response = yield axios({
      url: `/pujab/applicant-portal/applications/forms/${runningAdmissionId}`,
      method: "GET",
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_SUCCESS,
      data: response.data,
      runningAdmissionId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    getAllRunningAdmission,
  );
}

function* watchSubmitApplicationForm() {
  yield takeLatest(
    runningAdmissionActions.SUBMIT_APPLICANT_FORM_REQUEST,
    submitApplicationForm,
  );
}

function* watchGetSectionProgrammes() {
  yield takeLatest(
    runningAdmissionActions.GET_SELECTED_SECTION_PROGRAMMES_REQUEST,
    getSectionProgrammes,
  );
}

function* watchGetSubjectCombinations() {
  yield takeLatest(
    runningAdmissionActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    getSubjectCombinations,
  );
}

function* watchGetRunningAdmissionForms() {
  yield takeLatest(
    runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_REQUEST,
    getRunningAdmissionForms,
  );
}

const forkFunctions = [
  fork(watchGetRunningAdmission),
  fork(watchSubmitApplicationForm),
  fork(watchGetSectionProgrammes),
  fork(watchGetRunningAdmissionForms),
  fork(watchGetSubjectCombinations),
];

export default forkFunctions;
