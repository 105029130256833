import update from "immutability-helper";
import { isArray } from "lodash";
import { applicationSectionActions } from "../../actions";
import initialState from "../../initialState";

function applicationSection(state = initialState.applicationSection, actions) {
  switch (actions.type) {
    case applicationSectionActions.GET_APPLICATION_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case applicationSectionActions.GET_APPLICATION_SECTION_SUCCESS: {
      const { singleApplicationSections } = state;
      const { data, sectionUrl } = actions;

      const findDataIndex = singleApplicationSections.findIndex(
        (section) => section.section_url === sectionUrl,
      );

      const newFormattedData = {
        data,
        section_url: sectionUrl,
      };

      let newState = update(state, {
        singleApplicationSections: {
          $push: [newFormattedData],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          singleApplicationSections: {
            [findDataIndex]: { $set: newFormattedData },
          },
        });
      }

      return {
        ...newState,
        newFormId: isArray(data) ? data[0]?.form_id : data?.form_id,
        loading: false,
      };
    }

    case applicationSectionActions.GET_APPLICATION_SECTION_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case applicationSectionActions.CREATE_APPLICATION_SECTION_REQUEST:
      return {
        ...state,
        creating: true,
        sectionUrl: actions.sectionUrl,
        createError: {},
      };
    case applicationSectionActions.CREATE_APPLICATION_SECTION_SUCCESS:
      return {
        ...state,
        creating: false,
        currentApplicationForm: actions.data,
      };
    case applicationSectionActions.CREATE_APPLICATION_SECTION_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case applicationSectionActions.UPDATE_APPLICATION_SECTION_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case applicationSectionActions.UPDATE_APPLICATION_SECTION_SUCCESS:
      return {
        ...state,
        updating: false,
        updatedSection: actions.data,
      };
    case applicationSectionActions.UPDATE_APPLICATION_SECTION_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case applicationSectionActions.TRANSFER_PRN_PAYMENT_REQUEST:
      return {
        ...state,
        transferringFunds: true,
        transferError: {},
      };
    case applicationSectionActions.TRANSFER_PRN_PAYMENT_SUCCESS:
      return {
        ...state,
        transferringFunds: false,
        transferSuccess: actions.data,
      };
    case applicationSectionActions.TRANSFER_PRN_PAYMENT_ERROR:
      return {
        ...state,
        transferringFunds: false,
        transferError: actions.error,
      };

    case applicationSectionActions.DELETE_APPLICATION_SECTION_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteError: {},
      };
    case applicationSectionActions.DELETE_APPLICATION_SECTION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: actions.data,
      };
    case applicationSectionActions.DELETE_APPLICATION_SECTION_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case applicationSectionActions.GET_UNEB_RESULT_REQUEST:
      return {
        ...state,
        gettingUnebResults: true,
        unebResultError: {},
        unebResultSuccess: {},
      };
    case applicationSectionActions.GET_UNEB_RESULT_SUCCESS:
      return {
        ...state,
        gettingUnebResults: false,
        unebResultSuccess: actions.data,
      };
    case applicationSectionActions.GET_UNEB_RESULT_ERROR:
      return {
        ...state,
        gettingUnebResults: false,
        unebResultSuccess: {},
        unebResultError: actions.error,
      };

    case applicationSectionActions.CLEAR_UNEB_RESULT:
      return {
        ...state,
        unebResultSuccess: {},
      };

    case applicationSectionActions.SET_CURRENT_APPLICATION_FORM:
      return {
        ...state,
        currentApplicationForm: actions.payload,
      };

    case applicationSectionActions.SET_SCHOOL_APPLICATION_FORMS:
      return {
        ...state,
        schoolApplicationForms: actions.payload,
      };

    default:
      return state;
  }
}

export default applicationSection;
