import update from "immutability-helper";
import { findIndex } from "lodash";
import { runningAdmissionActions } from "../../actions";
import initialState from "../../initialState";

function runningAdmission(state = initialState.runningAdmission, actions) {
  switch (actions.type) {
    case runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        gettingActiveAdmission: true,
        getActiveAdmissionError: {},
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        runningAdmission: actions.data,
        gettingActiveAdmission: false,
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        getActiveAdmissionError: actions.error,
        gettingActiveAdmission: false,
      };

    case runningAdmissionActions.GET_SELECTED_SECTION_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingProgrammes: true,
        getProgrammesError: {},
      };
    case runningAdmissionActions.GET_SELECTED_SECTION_PROGRAMMES_SUCCESS: {
      const { programmes } = state;
      const { data, context } = actions;

      const findDataIndex = findIndex(
        programmes,
        (cnxt) =>
          cnxt.runningAdmissionId === context.runningAdmissionId &&
          cnxt.section === context.section,
      );

      const newFormattedData = {
        data,
        ...context,
      };

      let newState = update(state, {
        programmes: {
          $push: [newFormattedData],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          programmes: {
            [findDataIndex]: { $set: newFormattedData },
          },
        });
      }

      return {
        ...newState,
        gettingProgrammes: false,
      };
    }
    case runningAdmissionActions.GET_SELECTED_SECTION_PROGRAMMES_ERROR:
      return {
        ...state,
        getProgrammesError: actions.error,
        gettingProgrammes: false,
      };

    case runningAdmissionActions.GET_SUBJECT_COMBINATIONS_REQUEST:
      return {
        ...state,
        gettingSubjectCombinations: true,
        getSubjectCombinationError: {},
      };
    case runningAdmissionActions.GET_SUBJECT_COMBINATIONS_SUCCESS: {
      const { data } = actions;
      return {
        ...state,
        subjectCombinations: data,
        gettingSubjectCombinations: false,
      };
    }
    case runningAdmissionActions.GET_SUBJECT_COMBINATIONS_ERROR:
      return {
        ...state,
        getSubjectCombinationError: actions.error,
        gettingSubjectCombinations: false,
      };

    case runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_REQUEST:
      return {
        ...state,
        gettingForms: true,
        getFormError: {},
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_SUCCESS: {
      const { applicationForms } = state;
      const { data, runningAdmissionId } = actions;

      const findDataIndex = findIndex(
        applicationForms,
        (cnxt) => cnxt.runningAdmissionId === runningAdmissionId,
      );

      const contextData = {
        data,
        runningAdmissionId,
      };

      let newState = update(state, {
        applicationForms: {
          $push: [contextData],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          applicationForms: {
            [findDataIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingForms: false,
      };
    }
    case runningAdmissionActions.GET_RUNNING_ADMISSION_FORMS_ERROR:
      return {
        ...state,
        getFormError: actions.error,
        gettingForms: false,
      };

    case runningAdmissionActions.SUBMIT_APPLICANT_FORM_REQUEST:
      return {
        ...state,
        submittingForm: true,
        submitError: {},
        submittedForm: {},
      };

    case runningAdmissionActions.SUBMIT_APPLICANT_FORM_SUCCESS:
      return {
        ...state,
        submittingForm: false,
        submittedForm: actions.data,
      };

    case runningAdmissionActions.SUBMIT_APPLICANT_FORM_ERROR:
      return {
        ...state,
        submittingForm: false,
        submittedError: actions.error,
      };

    default:
      return state;
  }
}

export default runningAdmission;
