import initialState from "../initialState";

function settings(state = initialState.setting, action) {
  switch (action.type) {
    case "SET_SELECTED_MENU":
      return {
        ...state,
        selectedMenu: action.payload,
      };

    case "SWITCH_SIDE_MENU_TAB":
      return {
        ...state,
        sideMenuTab: action.payload,
      };

    case "SWITCH_RUNNING_ADMISSION_TAB":
      return {
        ...state,
        runningAdmissionTab: action.payload,
      };

    case "SET_APPLICATION_TAB":
      return {
        ...state,
        applicationTab: action.payload,
      };

    case "SHOW_SELECT_PROGRAMME_MODAL":
      return {
        ...state,
        showSelectProgrammeModal: action.payload,
      };

    case "SET_RUNNING_ADMISSION_FORM_SECTIONS":
      return {
        ...state,
        admissionFormSections: action.payload,
      };

    case "SHOW_GENERATE_PRN_MODAL":
      return {
        ...state,
        showPRNModal: action.payload,
      };

    default:
      return state;
  }
}

export default settings;
