const shared = {
  DOWNLOAD_EXCEL_FILE_REQUEST: "DOWNLOAD_EXCEL_FILE_REQUEST",
  DOWNLOAD_EXCEL_FILE_SUCCESS: "DOWNLOAD_EXCEL_FILE_SUCCESS",
  DOWNLOAD_EXCEL_FILE_ERROR: "DOWNLOAD_EXCEL_FILE_ERROR",

  DOWNLOAD_PDF_FILE_REQUEST: "DOWNLOAD_PDF_FILE_REQUEST",
  DOWNLOAD_PDF_FILE_SUCCESS: "DOWNLOAD_PDF_FILE_SUCCESS",
  DOWNLOAD_PDF_FILE_ERROR: "DOWNLOAD_PDF_FILE_ERROR",

  UPLOAD_EXCEL_FILE_REQUEST: "UPLOAD_EXCEL_FILE_REQUEST",
  UPLOAD_EXCEL_FILE_SUCCESS: "UPLOAD_EXCEL_FILE_SUCCESS",
  UPLOAD_EXCEL_FILE_ERROR: "UPLOAD_EXCEL_FILE_ERROR",

  downloadExcelFile: (url, fileName, ...rest) => ({
    type: shared.DOWNLOAD_EXCEL_FILE_REQUEST,
    url,
    fileName,
    ...rest,
  }),

  downloadPDFFile: (url, fileName, data, params, ...rest) => ({
    type: shared.DOWNLOAD_PDF_FILE_REQUEST,
    url,
    fileName,
    data,
    params,
    ...rest,
  }),

  uploadExcelFile: (url, data, ...rest) => ({
    type: shared.UPLOAD_EXCEL_FILE_REQUEST,
    url,
    data,
    ...rest,
  }),
};

export default shared;
