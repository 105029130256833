const appInitialState = {
  server: {
    error: {},
    success: {},

    verifyingGoogleToken: false,
    verifyGoogleTokenResponse: {},
    verifyGoogleTokenError: {},
  },
  metadata: {
    metadata: [],
    singleMetadata: {},
    metadataError: {},
    loading: false,
  },
  unebSubject: {
    loading: false,
    loadError: {},
    unebSubjects: [],

    getUnebCenterError: {},
    gettingUnebCenters: true,
    unebCenters: [],
  },
};

export default appInitialState;
