const settings = {
  setSelectedMenu: (e) => ({
    type: "SET_SELECTED_MENU",
    payload: e,
  }),

  switchSideMenuTab: (e) => ({
    type: "SWITCH_SIDE_MENU_TAB",
    payload: e,
  }),

  switchRunningAdmissionTab: (e) => ({
    type: "SWITCH_RUNNING_ADMISSION_TAB",
    payload: e,
  }),

  setApplicationTab: (e) => ({
    type: "SET_APPLICATION_TAB",
    payload: e,
  }),

  showSelectProgrammeModal: (e) => ({
    type: "SHOW_SELECT_PROGRAMME_MODAL",
    payload: e,
  }),

  setRunningAdmissionFormSections: (e) => ({
    type: "SET_RUNNING_ADMISSION_FORM_SECTIONS",
    payload: e,
  }),

  showGeneratePRN: (e = false) => ({
    type: "SHOW_GENERATE_PRN_MODAL",
    payload: e,
  }),
};

export default settings;
